import React from "react"
import Layout from './../components/shared/Layout';
import SectionArticles from './../components/section/SectionArticles';

const HomePage = () => (
  <Layout title="Eateries Review">
      <SectionArticles title="Latest Reviews"/>
  </Layout>
)

export default HomePage;
